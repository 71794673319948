document.addEventListener('DOMContentLoaded', () => {
  const header = document.querySelector('.header');
  const navbar = document.querySelector('.header__navbar');
  const sidebar = document.querySelector('.header__menuMobile');
  const btnOpen = document.querySelector('.header__burger');
  const btnClose = document.querySelector('.header__menuMobile > button');
  const topbar = document.querySelector('.header__topbar');

  btnOpen.addEventListener('click', () => {
    sidebar.classList.add('show');
  });

  btnClose.addEventListener('click', () => {
    sidebar.classList.remove('show');
  });

  window.addEventListener('scroll', () => {
    const positionScroll = window.scrollY;

    if (positionScroll > topbar.clientHeight) {
      navbar.classList.add('scrolled');
      header.style.transform = `translateY(-${topbar.clientHeight}px)`;
      header.style.boxShadow = `0 10px 10px -15px rgba(0,0,0,.5)`;
      sidebar.style.marginTop = `${topbar.clientHeight}px`;
    } else {
      navbar.classList.remove('scrolled');
      header.style.transform = `translateY(0)`;
      header.style.boxShadow = `none`;
      sidebar.style.marginTop = `0`;
    }
  });
});
